/* ================================ Home section 8 ================================ */

.panditCmp1Item {
  border: 1.5px solid rgb(106, 17, 16);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  padding: 20px;
}

.panditCmp1Item a {
  text-decoration: none;
}

.panditCmp1Item_borderDiv {
  display: flex;
}

.panditCmp1Item_borderDiv_sec1_ratingdiv {
  font-size: 13px !important;
  font-weight: 400;
  display: block;
  color: rgb(139, 139, 139);
}

.panditCmp1Item_borderDiv_sec1 {
  margin-right: 11px;
  width: 40%;
}

.panditCmp1Item_borderDiv_sec1_image_div {
  aspect-ratio: 1/0.7;
  display: flex;
  justify-content: center;
}

.panditCmp1Item_img {
  /* width: 70px; */
  /* aspect-ratio: 1; */
  max-width: 90%;
  border-radius: 10px;
  border: 1.5px solid rgb(106, 17, 16);
}

.panditCmp1Item_name {
  font-weight: 600 !important;
  font-size: 20px !important;
  color: rgb(106, 17, 16) !important;
}

.panditCmp1Item_borderDiv_sec2 span {
  display: block;
  color: rgb(139, 139, 139);
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 2px;
}

.panditCmp1Item_borderDiv_buttonBox {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.panditCmp1Item_borderDiv_buttonBox button {
  width: 48%;
  text-align: center;
  border: 1.5px solid rgb(106, 17, 16);
  font-weight: 600;
  color: rgb(106, 17, 16);
  border-radius: 10px;
  padding: 2px 5px;
}

.panditCmp1Item_borderDiv_buttonBox .filled {
  background-color: rgb(106, 17, 16);
  color: white;
}

/* ================================ Home section 8 ================================ */

/* ================================ pandit profile ================================ */
.profile_section_1_main_container {
  border: 1.5px solid rgb(106, 17, 16);
  border-radius: 5px;
}

/* .panditProfile_sec1 {
 
} */
.panditProfile_sec1_box1 {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.panditProfile_sec1_box1_imgBox {
  width: 35%;
}

.panditProfile_sec1_box1_imgBox img {
  width: 100%;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 1.5px solid rgb(106, 17, 16);
}

.panditProfile_sec1_box1_contentBox {
  width: 55%;
  margin-left: 5%;
}

.panditProfile_sec1_box1_contentBox_name {
  font-weight: 600 !important;
  font-size: 25px;
  color: rgb(106, 17, 16) !important;
  margin-bottom: 10px !important;
}

.panditProfile_sec1_box1_contentBox span {
  display: block;
  margin-bottom: 3px;
  font-weight: 500;
  color: rgb(139, 139, 139);
}

.panditProfile_sec1_box2_ratingspan {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.panditProfile_sec1_box2_ratingspan span {
  display: block;
  margin-bottom: 3px;
  font-weight: 500;
  color: rgb(139, 139, 139);
}

.panditProfile_sec1_box2 {
  height: 100%;
  position: relative;
}

.panditProfile_sec1_box2_ratingspan_container {
  position: absolute;
  width: 100%;
  bottom: 0;
}

.profile_section_1_main_container_aboutmebox h5 {
  color: rgb(106, 17, 16);
  font-weight: 500 !important;
}

.profile_section_1_main_container_aboutmebox p {
  display: block;
  margin-bottom: 3px;
  font-weight: 400;
  color: rgb(139, 139, 139);
}

.generalH4Heading {
  font-weight: 600;
  color: rgb(106, 17, 16);
}

/* ------------------------------------------------------------ */
.profile_section_2_sec1 {
  border-right: 2px solid rgb(106, 17, 16);
}

.profile_section_2_main_container {
  border: 1.5px solid rgb(106, 17, 16);
  border-radius: 5px;
}

.profile_section_2_ratingBox {
  flex-direction: column;
  align-items: flex-start !important;
}

@media screen and (max-width: 768px) {
  .profile_section_2_sec1 {
    border: none;
    border-bottom: 2px solid rgb(106, 17, 16);
  }
}

/* .profile_section_2_sec2_wcs {
} */
.profile_section_2_sec2_type {
  display: block;
  margin-bottom: 3px;
  font-weight: 500;
  color: rgb(139, 139, 139);
}

.profile_section_2_sec2_amount_box {
  background-color: rgb(255, 173, 172);
  border-radius: 50px;
}

.profile_section_2_sec2_amount {
  height: 7px;
  background-color: rgb(106, 17, 16);
  border-radius: 50px;
}

.profile_section_2_sec2_as_expected {
  display: block;
  margin-bottom: 3px;
  font-weight: 500;
  font-size: 12px;
  color: rgb(139, 139, 139);
}

.rating_box {
  box-shadow: 2px 2px 15px -10px;
  border-radius: 10;
}

.rating_text {
  color: rgb(139, 139, 139);
  font-size: medium;
  font-weight: 400;
  margin-bottom: 2px;
}

.rating_customer_icon {
  color: white;
  width: 30px;
  aspect-ratio: 1;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* ================================ pandit profile ================================ */