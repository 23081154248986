 .OnboardingForm .btnT1 {
     background-color: white !important;
     color: rgb(106, 17, 16);
     border: 2px solid rgb(106, 17, 16);
 }

 .OnboardingForm .btnT1 {
     background-color: rgb(106, 17, 16) !important;
     color: white !important;
     border: 2px solid rgb(106, 17, 16);
 }

 .OnboardingForm {
     box-shadow: 0 0 15px -10px black;
     border-radius: 10px;
 }