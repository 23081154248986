.main_header_element {
  background-color: rgb(254, 249, 237);
}
.main_header {
  padding: 5px 0px;
  display: flex;
  justify-content: space-between;
  /* flex-wrap: wrap; */
}

.main-logo {
  height: 80px;
}

.main_header_sec1 {
  display: flex;
  align-items: center;
  gap: 10px;
}
.main_header_sec1_icon1box i {
  color: white;
  background-color: rgba(106, 17, 16, 1);
  padding: 8px;
  border-radius: 50%;
  font-size: 13px;
  width: 30px;
  text-align: center;
}

.header_img_icon {
  color: white;
  background-color: rgba(106, 17, 16, 1);
  padding: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  font-size: 13px;
  width: 30px;
  text-align: center;
}
.header_img_icon img {
  height: 20px;
  aspect-ratio: 1;
}

.main_header_sec1_phone {
  color: rgba(106, 17, 16, 1);
  font-weight: bold;
}

/* --------------------- */
.header_main_sec3 {
  display: flex;
  align-items: center;
  gap: 10px;
}

@media screen and (max-width: 500px) {
  .header_main_sec3 {
    margin: auto;
  }

  .main_header_sec1 {
    margin: auto;
  }
}

.header_main_sec3 i {
  color: white;
  background-color: rgba(106, 17, 16, 1);
  padding: 8px;
  border-radius: 50%;
  font-size: 13px;
  width: 34px;
  text-align: center;
  aspect-ratio: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}

.overflow_formBox {
  position: relative;
  z-index: 100;
}
