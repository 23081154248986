.profile_input {
    border: none !important;
    background-color: none !important;
    background: none !important;
    color: rgb(138, 149, 161);
    padding: 0;
}

.profile_input_edit {
    color: rgb(138, 149, 161);
    background: none !important;
}

.profile_lable {
    color: rgb(78, 88, 97);
}